import {enUS, esES, frFR} from "@mui/material/locale";
// routes
import {PATH_DASHBOARD, PATH_OPERATIONS_CENTER, PATH_RESOURCE_CENTER} from './routes/paths';
import Iconify from "./components/Iconify";
import {each, find, flatMap, groupBy, intersection, keys, orderBy, sortBy, startCase, values} from "lodash";
// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST || '';
export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const PASSWORD_COMPLEXITY = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const TOW_TRUCK_MODEL = {
    SYMPTOMS: ["Emergency", "Accident", "Car Won't Start", "Illegally Parked", "Flat Tire", "Out of Fuel", "Locked Out", "Vehicle Stuck", "Overheated Engine", "Cosmetic Repairs"],
    TYPES: ["Tow", "Roadside Assistance", "Specialized"],
    CATEGORIES: ["Lockout", "Fuel", "Windshield", "Tire & Wheel", "Dent & Ding", "Battery", "Flatbed", "Lift", "Gooseneck", "Winching", "Recovery", "Lowboy Trailer", "Ball & Hitch", "Hook and Chain"],
    SUB_CATEGORIES: ["Inspection", "Repossession", "Charge", "Accident", "Locksmith", "Key Replacement", "Diesel", "Unleaded", "Repair", "Replace", "Inflation", "Change", "Cosmetic", "Jump", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    CLASSES: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    MODIFIERS: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
}

export const US_TIME_ZONES = [
    'America/New_York',      // Eastern Time
    'America/Chicago',       // Central Time
    'America/Denver',        // Mountain Time
    'America/Phoenix',       // Arizona (No DST)
    'America/Los_Angeles',   // Pacific Time
    'America/Anchorage',     // Alaska Time
    'Pacific/Honolulu',      // Hawaii Time (No DST)
    'America/Puerto_Rico',   // Puerto Rico (No DST)
    'Pacific/Pago_Pago',     // American Samoa (No DST)
    'Pacific/Guam'           // Guam (No DST)
];

export const COMPANY_SIZING = {
    EMPLOYEES: [
        {
            name: 'Microenterprise',
            value: '1-9 employees',
            range: {
                min: 1,
                max: 9
            }
        },
        {
            name: 'Small business',
            value: '10-49 employees',
            range: {
                min: 10,
                max: 49
            }
        },
        {
            name: 'Medium-sized business',
            value: '50-249 employees',
            range:
                {
                    min: 50,
                    max: 249
                }
        },
        {
            name: 'Large business',
            value: '250-999 employees',
            range:
                {
                    min: 250,
                    max: 999
                }
        },
        {
            name: 'Enterprise',
            value: '1,000-4,999 employees',
            range:
                {
                    min: 1000,
                    max: 4999
                }
        },
        {
            name: 'Large enterprise',
            value: '5,000-9,999 employees',
            range:
                {
                    min: 5000,
                    max: 9999
                }
        },
        {
            name: 'Very large enterprise',
            value: '10,000-99,999 employees',
            range:
                {
                    min: 10000,
                    max: 99999
                }
        },
        {
            name: 'Mega-corporation',
            value: '100,000 or more employees',
            range: {
                min: 100000,
                max: null
            }
        }
    ],
    REVENUE: [
        {
            name: 'Microenterprise',
            value: 'Less than $1 million in annual revenue',
            range: {
                min: null,
                max: 1000000
            }
        },
        {
            name: 'Small business',
            value: '$1 million to $10 million in annual revenue',
            range:
                {
                    min: 1000000,
                    max: 10000000
                }
        },
        {
            name: 'Medium-sized business',
            value: '$10 million to $1 billion in annual revenue',
            range:
                {
                    min: 10000000,
                    max: 1000000000
                }
        },
        {
            name: 'Large business',
            value: '$1 billion to $10 billion in annual revenue',
            range:
                {
                    'name': 'Large business',
                    min: 1000000000,
                    max: 10000000000
                }
        },
        {
            name: 'Enterprise',
            value: '$10 billion to $50 billion in annual revenue',
            range:
                {
                    min: 10000000000,
                    max: 50000000000
                }
        },
        {
            name: 'Large enterprise',
            value: '$50 billion to $100 billion in annual revenue',
            range:
                {
                    min: 50000000000,
                    max: 100000000000
                }
        },
        {
            name: 'Very large enterprise',
            value: '$100 billion or more in annual revenue',
            range:
                {
                    min: 100000000000,
                    max: null
                }
        }
    ]
};

export const TYPES_CATEGORY_COMBOS = {
    "Tow": ["Flatbed", "Lift", "Gooseneck", "Lowboy Trailer", "Ball & Hitch", "Hook and Chain"],
    "Roadside Assistance": ["Lockout", "Fuel", "Windshield", "Tire & Wheel", "Dent & Ding", "Battery"],
    "Specialized": ["Windshield", "Tire & Wheel", "Dent & Ding", "Recovery"]
};

export const CATEGORY_SUB_CATEGORY_COMBOS = {
    "Lockout": ["Locksmith", "Key Replacement"],
    "Fuel": ["Diesel", "Unleaded"],
    "Windshield": ["Inspection", "Repair", "Replace"],
    "Tire & Wheel": ["Inspection", "Repair", "Replace", "Inflation", "Change", "Cosmetic"],
    "Dent & Ding": ["Inspection", "Repair", "Cosmetic"],
    "Battery": ["Inspection", "Charge", "Replace", "Change", "Jump"],
    "Flatbed": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Lift": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Gooseneck": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Winching": ["All Terrain"],
    "Recovery": ["Repossession", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Lowboy Trailer": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Ball & Hitch": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
    "Hook and Chain": ["Repossession", "Accident", "Storage", "Impound", "Two Way", "All Terrain", "Transportation"],
};

export const CATEGORY_CLASS_COMBOS = {
    "Lockout": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Fifth Wheel"],
    "Fuel": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
    "Windshield": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Fifth Wheel"],
    "Tire & Wheel": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer", "Fifth Wheel"],
    "Dent & Ding": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Fifth Wheel"],
    "Battery": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
    "Flatbed": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Lift": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Gooseneck": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Winching": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Recovery": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Lowboy Trailer": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV", "Fifth Wheel"],
    "Ball & Hitch": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
    "Hook and Chain": ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty"],
};

export const CLASS_MODIFIER_COMBOS = {
    "Light Duty": ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"],
    "Medium Duty": ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"],
    "Heavy Duty": ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"],
    "Super Duty": ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"],
    "Motorcycle": ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"],
    "RV": ["Hybrid", "EV", "Luxury", "Exotic"],
    "Water Vehicle": ["Luxury", "Exotic", "Low Clearance"],
    "Trailer": ["Luxury", "Exotic", "Low Clearance"],
    "Fifth Wheel": ["Luxury", "Exotic"]
};

export const TOW_TRUCK_MODEL_DESCRIPTIONS = {
    CLASSES: [
        {label: "Light Duty", description: "Vehicles weighing up to 6,000 pounds, e.g., sedans, SUVs"},
        {label: "Medium Duty", description: "Vehicles weighing 6,001 to 10,000 pounds, e.g., vans, small trucks"},
        {label: "Heavy Duty", description: "Vehicles weighing 10,001 to 26,000 pounds, e.g., large trucks"},
        {label: "Super Duty", description: "Extremely heavy-duty vehicles"},
        {label: "Motorcycle", description: "Two-wheeled motor vehicles"},
        {label: "RV", description: "Recreational Vehicles, motorhomes"},
        {label: "Water Vehicle", description: "Boats, jet skis, and other watercraft"},
        {label: "Trailer", description: "Non-motorized towed vehicles"},
        {label: "Fifth Wheel", description: "Services for vehicles with fifth-wheel connections"},
    ],
    TYPES: [
        {label: "Tow", description: "Standard towing services for disabled vehicles"},
        {label: "Roadside Assistance", description: "Emergency services like jump-starts, tire changes, and lockouts"},
        {
            label: "Specialized",
            description: "Specialized services for unique situations like windshields or dent and ding"
        },
    ],
    CATEGORIES: [
        {label: "EV", description: "Electric Vehicle-related services"},
        {label: "Lockout", description: "Assistance with vehicle lockouts"},
        {label: "Fuel", description: "Fuel delivery services"},
        {label: "Windshield", description: "Windshield repair and replacement services"},
        {label: "Tire & Wheel", description: "Tire replacement and wheel-related services"},
        {label: "Dent & Ding", description: "Repair services for vehicle dents and dings"},
        {label: "Battery", description: "Battery-related services, including jump-starts and replacements"},
        {label: "Flatbed", description: "Services using flatbed trucks for transportation"},
        {label: "Lift", description: "Services using lifting equipment for heavy vehicles"},
        {label: "Gooseneck", description: "Services using gooseneck trailers for towing"},
        {label: "Winching", description: "Vehicle winching and recovery services"},
        {label: "Recovery", description: "Specialized vehicle recovery services"},
        {label: "Lowboy Trailer", description: "Services using lowboy trailers for transportation"},
        {label: "Ball & Hitch", description: "Services using ball and hitch equipment for towing"},
        {label: "Hook and Chain", description: "Services using hook and chain equipment for towing"},
    ],
    SUB_CATEGORIES: [
        {label: "Inspection", description: "Vehicle inspection services"},
        {label: "Repossession", description: "Vehicle repossession services"},
        {label: "Charge", description: "Charging-related services for electric vehicles"},
        {label: "Accident", description: "Services related to accidents, including towing"},
        {label: "Locksmith", description: "Locksmith services for vehicles"},
        {label: "Key Replacement", description: "Key replacement services for vehicles"},
        {label: "Diesel", description: "Services for vehicles with diesel engines"},
        {label: "Unleaded", description: "Services for vehicles with unleaded fuel"},
        {label: "Repair", description: "Vehicle repair services"},
        {label: "Replace", description: "Vehicle replacement services"},
        {label: "Inflation", description: "Tire inflation services"},
        {label: "Change", description: "Oil change services"},
        {label: "Cosmetic", description: "Cosmetic vehicle services"},
        {label: "Jump", description: "Jump-start services for dead batteries"},
        {label: "Storage", description: "Vehicle storage services"},
        {label: "Impound", description: "Vehicle impound services"},
        {label: "Two Way", description: "Two-way communication-equipped vehicles"},
        {label: "All Terrain", description: "Services for all-terrain vehicles"},
        {label: "Transportation", description: "General vehicle transportation services"},
    ],
    MODIFIERS: [
        {label: "Hybrid", description: "Services for hybrid vehicles"},
        {label: "EV", description: "Services for electric vehicles"},
        {label: "Luxury", description: "Services for luxury vehicles"},
        {label: "Exotic", description: "Services for exotic vehicles"},
        {label: "Low Clearance", description: "Services for vehicles with low ground clearance"},
    ],
};

export const BASE_AUTO_SERVICES = [
    {
        active: true,
        name: "Winching",
        type: 'Roadside Assistance',
        category: "Winching",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Exotic", "Luxury", "Low Clearance"],
    },
    {
        active: true,
        name: "Charging",
        type: 'Roadside Assistance',
        category: "Battery",
        subCategory: 'Charge',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Exotic", "Luxury", "Low Clearance"],
    },
    {
        active: true,
        name: "Accident Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "Accident",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Storage Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "Storage",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Impound Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "Impound",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Two Way Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "Two Way",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "All Terrain Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Transportation Flatbed Tow",
        type: 'Tow',
        category: "Flatbed",
        subCategory: "Transportation",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Transportation Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "Transportation",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "All Terrain Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "All Terrain",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Two Way Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "Two Way",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Impound Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "Impound",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Storage Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "Storage",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Accident Ball & Hitch Tow",
        type: 'Tow',
        category: "Ball & Hitch",
        subCategory: "Accident",
        classes: ["Water Vehicle", "Trailer", "Fifth Wheel"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Transportation Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "Transportation",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "All Terrain Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Two Way Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "Two Way",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Impound Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "Impound",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Storage Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "Storage",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Accident Lift Tow",
        type: 'Tow',
        category: "Lift",
        subCategory: "Accident",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Transportation Hook and Chain Tow",
        type: 'Tow',
        category: "Hook and Chain",
        subCategory: "Transportation",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "All Terrain Hook and Chain Tow",
        type: 'Tow',
        category: "Hook and Chain",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Two Way Hook and Chain Tow",
        type: 'Tow',
        category: "Hook and Chain",
        subCategory: "Two Way",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Impound Hook and Chain Tow",
        type: 'Tow',
        category: "Hook and Chain",
        subCategory: "Impound",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Accident Hook and Chain Tow",
        type: 'Tow',
        category: "Hook and Chain",
        subCategory: "Accident",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Transportation Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "Transportation",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "All Terrain Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "Two Way",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Impound Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "Impound",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Storage Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "Storage",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "Accident Gooseneck Tow",
        type: 'Tow',
        category: "Gooseneck",
        subCategory: "Accident",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic"]
    },
    {
        active: true,
        name: "All Terrain Recovery",
        type: "Specialized",
        category: "Recovery",
        subCategory: "All Terrain",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Two Way Recovery",
        type: "Specialized",
        category: "Recovery",
        subCategory: "Two Way",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Impound Recovery",
        type: "Specialized",
        category: "Recovery",
        subCategory: "Impound",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Storage Recovery",
        type: "Specialized",
        category: "Recovery",
        subCategory: "Storage",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Repossession",
        type: "Specialized",
        category: "Recovery",
        subCategory: "Repossession",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Accident Recovery",
        type: "Specialized",
        category: "Recovery",
        subCategory: "Accident",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Lockout",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Locksmith",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Vehicle Lockout Assistance",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Locksmith",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Key Replacement",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Key Replacement",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Locksmithh Services",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Locksmith",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Broken Key Extraction",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Transponder Key Programming",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Smart Key Programming",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Remote Key Fob Replacement",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Key Replacement",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Key Duplication",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Key Replacement",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Ignition Key Services",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Electronic Lock Troubleshooting",
        type: 'Roadside Assistance',
        category: "Lockout",
        subCategory: "Locksmith",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Fuel Delivery - Unleaded",
        type: 'Roadside Assistance',
        category: "Fuel",
        subCategory: "Unleaded",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Fuel Delivery - EV",
        type: 'Roadside Assistance',
        category: "Fuel",
        subCategory: 'Unleaded',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty"],
        modifiers: ["Exotic", "Luxury", "Hybrid"],
    },
    {
        active: true,
        name: "Fuel Delivery - EV",
        type: 'Roadside Assistance',
        category: "Fuel",
        subCategory: 'Diesel',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty"],
        modifiers: ["Exotic", "Luxury", "Hybrid"],
    },
    {
        active: true,
        name: "Fuel Delivery - Diesel",
        type: 'Roadside Assistance',
        category: "Fuel",
        subCategory: "Diesel",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Repair",
        category: "Windshield",
        subCategory: "Repair",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Replacement",
        category: "Windshield",
        subCategory: "Replace",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Inspection",
        category: "Windshield",
        subCategory: "Inspection",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Polishing",
        category: "Windshield",
        subCategory: "Cosmetic",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Sealing",
        category: "Windshield",
        subCategory: "Repair",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Tinting",
        category: "Windshield",
        subCategory: "Cosmetic",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Scratch Removal",
        category: "Windshield",
        subCategory: "Repair",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Wiper Replacement",
        category: "Windshield",
        subCategory: "Replace",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Fluid Refill",
        category: "Windshield",
        subCategory: "Replace",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Windshield Calibration",
        category: "Windshield",
        subCategory: "Inspection",
        type: 'Specialized',
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Water Vehicle", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Dent Repair",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Ding Removal",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Paintless Dent Repair",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Hail Damage Repair",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Panel Beating",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Auto Body Dent Repair",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Minor Dent Restoration",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Door Ding Repair",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Fender Dent Removal",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Paint Touch-Up for Dents",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Dent and Ding Inspection",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Inspection",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Dent and Ding Prevention",
        type: 'Specialized',
        category: "Dent & Ding",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Wheel Cosmetic Repair",
        type: 'Specialized',
        category: "Tire & Wheel",
        subCategory: "Cosmetic",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Tire Change",
        type: 'Roadside Assistance',
        category: "Tire & Wheel",
        subCategory: "Change",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Tire Inflation",
        type: 'Roadside Assistance',
        category: "Tire & Wheel",
        subCategory: "Inflation",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Tire Replace",
        type: 'Roadside Assistance',
        category: "Tire & Wheel",
        subCategory: "Replace",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Tire Repair",
        type: 'Roadside Assistance',
        category: "Tire & Wheel",
        subCategory: "Repair",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV", "Trailer"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Battery Charge",
        type: 'Roadside Assistance',
        category: "Battery",
        subCategory: "Charge",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Battery Replace",
        type: 'Roadside Assistance',
        category: "Battery",
        subCategory: "Replace",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    },
    {
        active: true,
        name: "Jump",
        type: 'Roadside Assistance',
        category: "Battery",
        subCategory: "Jump",
        classes: ["Light Duty", "Medium Duty", "Heavy Duty", "Super Duty", "Motorcycle", "RV"],
        modifiers: ["Hybrid", "EV", "Luxury", "Exotic", "Low Clearance"]
    }
]

/*
1HGCM82633A123456	Ford	F-550	2021
2T3DFREV4JW123457	Chevrolet	Silverado 3500	2022
JH4DA9350HS012345	Dodge	Ram 4500	2020
1C6RR7GG0HS456789	GMC	Sierra 2500	2021
5NPEB4AC3CH678901	International	4300	2019
JTDKN3DU0D1709876	Kenworth	T880	2023
1GTHK23658F456789	Peterbilt	389	2022
1FDUF5HT4JEC01234	Freightliner	M2 106	2021
1HTMMAAL5CH234567	Mack	Granite GU813	2020
1FVACWDT4KH789012	Volvo	VHD64F	2023
 */

export const CARS_TRAINING = [
    {
        vin: "5YJ3E1EA5PF469621",
        make: "Ford",
        model: "F-150",
        year: 2023,
        videos: [{
            title: "Know Before You Tow - Tesla Model 3",
            description: 'An introductory video covering the fundamentals of operating a tow truck safely and efficiently.',
            url: 'https://www.youtube.com/embed/zjnOte6kcW8?si=nvRXEtTtoSoN8sI5'
        }]
    },
    {
        vin: "3FMTK4SE4NMA26480",
        make: "Ford",
        model: "F-150",
        year: 2022,
        videos: [{
            title: 'Basic Tow Truck Operation',
            description: 'An introductory video covering the fundamentals of operating a tow truck safely and efficiently.',
            url: 'https://www.youtube.com/embed/_8QFc3FPJSg?si=s-b5JCtxD-MI4h4J'
        }]
    }
]

export const TOW_TRUCKS_TRAINING = [
    {
        vin: "1HGCM82633A123456",
        make: "Ford",
        model: "F-550",
        year: 2021,
        videos: [{
            title: 'Basic Tow Truck Operation',
            thumbnail: '/totrucktech.png',
            description: 'An introductory video covering the fundamentals of operating a tow truck safely and efficiently.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "2T3DFREV4JW123457",
        make: "Chevrolet",
        model: "Silverado 3500",
        year: 2022,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        },
            {
                title: 'Basic Tow Truck Operation',
                thumbnail: '/totrucktech.png',
                description: 'An introductory video covering the fundamentals of operating a tow truck safely and efficiently.',
                url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
            }]
    },
    {
        vin: "JH4DA9350HS012345",
        make: "Dodge",
        model: "Ram 4500",
        year: 2020,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1C6RR7GG0HS456789",
        make: "GMC",
        model: "Sierra 2500",
        year: 2021,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "5NPEB4AC3CH678901",
        make: "International",
        model: "4300",
        year: 2019,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "JTDKN3DU0D1709876",
        make: "Kenworth",
        model: "T880",
        year: 2023,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1GTHK23658F456789",
        make: "Peterbilt",
        model: "389",
        year: 2022,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1FDUF5HT4JEC01234",
        make: "Freightliner",
        model: "M2 106",
        year: 2021,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1HTMMAAL5CH234567",
        make: "Mack",
        model: "Granite GU813",
        year: 2020,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1FVACWDT4KH789012",
        make: "Volvo",
        model: "VHD64F",
        year: 2023,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    },
    {
        vin: "1FVACWDT4KH789012",
        make: "Isuzu",
        model: "FTR",
        year: 2024,
        videos: [{
            title: 'The scale doesn\'t lie, Light Duty Rollbacks are being overworked.',
            thumbnail: '/totrucktech.png',
            description: 'We finally get the Rivian R1S on the back of a Light Duty Carrier as previously promised and it went about as well as expected.  We also roll across the scale loaded up with a Mercedes Benz GL63 AMG and Ford Fusion.',
            url: 'https://www.youtube.com/embed/H8DUJOd0unU?si=myJbeajiYZOsXyxr'
        }, {
            title: '8 Point Tie Down Kits on Rollbacks',
            thumbnail: '/totrucktech.png',
            description: 'You hear me talking about strapping down these cars we review with an 8 point kit during towing events, we get into what that’s all about today.  I’m going to show you the right way, and a few wrong ways, cars are handled during towing events as well as where some damages might originate.',
            url: 'https://www.youtube.com/embed/jimoxrq5vPQ?si=qjUonVO6nYeKht4r'
        }]
    }
]

function mapSymptoms(service) {
    if (service.type === 'Tow') {
        if (service.name.includes("Accident")) {
            service.symptoms = ["Accident", "Car Won't Start", "Overheated Engine"];
        } else if (service.name.includes("Impound")) {
            service.symptoms = ["Illegally Parked"];
        } else {
            service.symptoms = ["Vehicle Stuck", "Emergency"];
        }
    } else if (service.type === 'Roadside Assistance') {
        switch (service.category) {
            case "Lockout":
                service.symptoms = ["Locked Out"];
                break;
            case "Winching":
                service.symptoms = ["Vehicle Stuck"];
                break;
            case "Fuel":
                service.symptoms = ["Out of Fuel"];
                break;
            case "Battery":
                service.symptoms = ["Car Won't Start"];
                break;
            case "Tire & Wheel":
                service.symptoms = ["Flat Tire"];
                break;
            default:
                service.symptoms = [];
        }
    } else if (service.type === 'Specialized') {
        switch (service.category) {
            case "Tire & Wheel":
                service.symptoms = ["Cosmetic Repairs"];
                break;
            case "Windshield":
                service.symptoms = ["Cosmetic Repairs"];
                break;
            case "Dent & Ding":
                service.symptoms = ["Cosmetic Repairs", "Accident"];
                break;
            case "Recovery":
                service.symptoms = ["Vehicle Stuck", "Emergency", "Accident"];
                break;
            default:
                service.symptoms = [];
        }
    }
    return service;
}


function buildAutoServices() {
    let autoServices = [];
    each(BASE_AUTO_SERVICES, (baseService) => {
        const {
            category,
            classes: baseClasses,
            modifiers: baseModifiers
        } = baseService;
        const classes = baseClasses.filter((cls) => CATEGORY_CLASS_COMBOS[category].includes(cls));
        const modifiers = baseModifiers.filter((modifier) => classes.some((cls) => CLASS_MODIFIER_COMBOS[cls].includes(modifier)));
        autoServices.push(mapSymptoms({
            ...baseService,
            classes,
            modifiers
        }))
    })
    return autoServices;
}

export const AUTO_SERVICES = buildAutoServices()

export const HOME_SERVICES = [
    {
        "category": "Home Maintenance and Repairs",
        "name": "Plumbing services",
        "code": "PS",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific plumbing issues do you need help with?",
                "identifier": "aa6c2c0f-34f9-4b9e-9b44-e1b692a33d5a",
                "type": "text"
            },
            {
                "question": "Are you experiencing any leaks or water-related emergencies?",
                "identifier": "cd22a98c-95d7-4f3f-a25d-d0ef58d85a3f",
                "type": "boolean"
            }
        ]
    },
    {
        "category": "Home Maintenance and Repairs",
        "name": "Electrical services",
        "code": "ES",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What electrical repairs or installations do you need assistance with?",
                "identifier": "ab74e63d-9750-4e8e-b919-356fe38a5377",
                "type": "text"
            },
            {
                "question": "Have you experienced any power outages or electrical hazards?",
                "identifier": "c285d7e1-048f-4c35-9586-45cc3e52c708",
                "type": "boolean"
            }
        ]
    },
    {
        "category": "Home Maintenance and Repairs",
        "name": "Handyman services",
        "code": "HS",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific tasks do you need help with?",
                "identifier": "cbd0594c-ec24-4b34-b832-5099f61c015b",
                "type": "text"
            },
            {
                "question": "Are these tasks indoors or outdoors?",
                "identifier": "69cc01b6-1f81-4003-8785-85d502d8c6d7",
                "type": "text"
            }
        ]
    },
    {
        "category": "Home Maintenance and Repairs",
        "name": "HVAC services",
        "code": "HV",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What HVAC services do you require?",
                "identifier": "1a786c32-8139-4955-b1cd-3351681f23c5",
                "type": "text"
            },
            {
                "question": "Is this for a residential or commercial property?",
                "identifier": "4d0b6f82-11cc-4c28-b2dd-e39ef9a7265b",
                "type": "text"
            }
        ]
    },
    {
        "category": "Home Maintenance and Repairs",
        "name": "Appliance repairs",
        "code": "AR",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "Which appliances need repair?",
                "identifier": "523f8c2b-9466-47d2-af9b-6566ea82a4e2",
                "type": "text"
            },
            {
                "question": "Are these appliances still under warranty?",
                "identifier": "b37b26d9-0073-4c72-b856-9f1c19bfb944",
                "type": "boolean"
            }
        ]
    },
    {
        "category": "Cleaning and Organization",
        "name": "House cleaning",
        "code": "HC",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific areas of your house need cleaning?",
                "identifier": "d6c68e56-88f0-4e2b-b206-b3fbaccd1e42",
                "type": "text"
            },
            {
                "question": "Do you have any specific cleaning preferences or instructions?",
                "identifier": "4e9bc5ab-3e78-4463-9c5a-54f2e30f8a46",
                "type": "text"
            }
        ]
    },
    {
        "category": "Cleaning and Organization",
        "name": "Carpet and upholstery cleaning",
        "code": "CU",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "Which carpets and upholstery items do you want to get cleaned?",
                "identifier": "0a86bdf6-9f49-429b-9279-9f55344190db",
                "type": "text"
            },
            {
                "question": "Are there any specific stains or issues you want to address?",
                "identifier": "f820f1cd-0bb4-4e1b-bf05-6e3c0763b3b0",
                "type": "text"
            }
        ]
    },
    {
        "category": "Cleaning and Organization",
        "name": "Professional organizing",
        "code": "PO",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "Which areas or spaces need professional organizing?",
                "identifier": "69d9b54e-8b54-46f0-825a-8c7e6375d006",
                "type": "text"
            },
            {
                "question": "What are your specific organizing goals or requirements?",
                "identifier": "de4d32b2-c96a-47f5-9d87-e0468622aabc",
                "type": "text"
            }
        ]
    },
    {
        "category": "Personal Care and Well-being",
        "name": "Personal training",
        "code": "PT",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What are your fitness goals and objectives?",
                "identifier": "e04a79a6-7293-459c-8f8d-100a36350f8b",
                "type": "text"
            },
            {
                "question": "Do you have any medical conditions or physical limitations to consider?",
                "identifier": "fb69001f-1c84-4d06-8cc2-94daa26ad6b3",
                "type": "text"
            }
        ]
    },
    {
        "category": "Personal Care and Well-being",
        "name": "Massage therapy",
        "code": "MT",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific type of massage therapy are you interested in?",
                "identifier": "2208a7f1-b617-46bb-b14f-54677ed2e90a",
                "type": "text"
            },
            {
                "question": "Do you have any specific areas or issues you want to address with the massage?",
                "identifier": "ef8eae27-cd7b-4dc2-8e38-84d1d06389b2",
                "type": "text"
            }
        ]
    },
    {
        "category": "Personal Care and Well-being",
        "name": "Yoga and meditation",
        "code": "YM",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What type of yoga and meditation practices are you interested in?",
                "identifier": "6f30e3a0-5ec7-4a1e-a3a9-df32ad5e54eb",
                "type": "text"
            },
            {
                "question": "Are you a beginner or experienced practitioner?",
                "identifier": "f67b4193-3a7c-45cb-97b9-6ef5ed6cb47f",
                "type": "text"
            }
        ]
    },
    {
        "category": "Personal Care and Well-being",
        "name": "Home health care",
        "code": "HH",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific home health care services do you require?",
                "identifier": "ac104d21-1095-413d-a3fc-c3ef6c03271d",
                "type": "text"
            },
            {
                "question": "Do you have any specific medical conditions or requirements to consider?",
                "identifier": "4569b2d8-e857-4d33-8e5f-e0daa396e586",
                "type": "text"
            }
        ]
    },
    {
        "category": "Pet Care",
        "name": "Dog walking",
        "code": "DW",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "How many dogs do you need to be walked?",
                "identifier": "9fc84a3d-b40f-4a5e-9e6e-4ea61c1c31f5",
                "type": "text"
            },
            {
                "question": "What is the desired duration and frequency of the walks?",
                "identifier": "19275152-2ed1-4d4b-b4fc-2a4997946d79",
                "type": "text"
            }
        ]
    },
    {
        "category": "Pet Care",
        "name": "Pet grooming",
        "code": "PG",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "Which pets require grooming?",
                "identifier": "a16831a3-13bb-450c-882e-9f8b044aadfc",
                "type": "text"
            },
            {
                "question": "What specific grooming services do you need for your pets?",
                "identifier": "c4d267f3-5f9f-41f6-9925-258847c2267a",
                "type": "text"
            }
        ]
    },
    {
        "category": "Pet Care",
        "name": "Pet sitting",
        "code": "PS",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "How many pets do you need to be taken care of?",
                "identifier": "1ac3c27b-9006-4ac4-8e27-68c51e90381f",
                "type": "text"
            },
            {
                "question": "What is the duration and frequency of pet sitting required?",
                "identifier": "d3d67465-0e95-43db-8d7f-5b1d6c2c46bf",
                "type": "text"
            }
        ]
    },
    {
        "category": "Home Improvement and Renovation",
        "name": "Interior design consultations",
        "code": "ID",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific areas of your home need interior design consultation?",
                "identifier": "b9885e62-8699-41d2-bf3f-ec1a3e935399",
                "type": "text"
            },
            {
                "question": "Do you have any specific design preferences or styles in mind?",
                "identifier": "e72b53dd-2953-45f0-93a0-e215b43763c1",
                "type": "text"
            }
        ]
    },
    {
        "category": "Home Improvement and Renovation",
        "name": "Home remodeling",
        "code": "HR",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific areas of your home require remodeling?",
                "identifier": "b17d5545-491f-47cc-8d3b-60326cd3aa57",
                "type": "text"
            },
            {
                "question": "What are your remodeling goals and requirements?",
                "identifier": "af0d098e-56d1-44ac-a721-98648a4e7c66",
                "type": "text"
            }
        ]
    },
    {
        "category": "Home Improvement and Renovation",
        "name": "Landscaping and gardening",
        "code": "LG",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific landscaping or gardening services do you need?",
                "identifier": "bde5a6c2-af7c-426c-aa06-e0e25c8b2c5a",
                "type": "text"
            },
            {
                "question": "Do you have any specific preferences or ideas for your landscape or garden?",
                "identifier": "eff2b738-27a7-4f1a-9bea-16b52e70cd3a",
                "type": "text"
            }
        ]
    },
    {
        "category": "Online Services and Consultations",
        "name": "Online tutoring",
        "code": "OT",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What subjects or topics do you need online tutoring for?",
                "identifier": "9f17e7d6-598e-4c2a-80c9-bb1e1000c8d8",
                "type": "text"
            },
            {
                "question": "What is your preferred schedule for tutoring sessions?",
                "identifier": "267f240e-9873-4021-bf32-27c9fb63e118",
                "type": "text"
            }
        ]
    },
    {
        "category": "Online Services and Consultations",
        "name": "Virtual therapy and counseling",
        "code": "VC",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific areas or issues do you want to address in virtual therapy or counseling?",
                "identifier": "223c183f-404b-4c89-a26a-6d4a1041e6b9",
                "type": "text"
            },
            {
                "question": "Do you have any preferences for the therapist's expertise or background?",
                "identifier": "dcf485b5-69c1-4a0d-aa77-e7af5508ca5d",
                "type": "text"
            }
        ]
    },
    {
        "category": "Online Services and Consultations",
        "name": "Virtual assistance",
        "code": "VA",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific tasks or assistance do you need from a virtual assistant?",
                "identifier": "b7b2b9ea-e1c2-418f-aeeb-7d0b746be5f6",
                "type": "text"
            },
            {
                "question": "Are there any specific software or tools you prefer the virtual assistant to use?",
                "identifier": "7a9295cc-c26c-4ac7-a95a-27d8d59b2180",
                "type": "text"
            }
        ]
    },
    {
        "category": "Online Services and Consultations",
        "name": "Remote tech support",
        "code": "RT",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific technical issues or devices do you need remote tech support for?",
                "identifier": "bdd99b75-78b0-4e63-bc58-e8e401e77410",
                "type": "text"
            },
            {
                "question": "Do you have any error messages or symptoms related to the technical issue?",
                "identifier": "c2c0522e-4811-4cfb-9a14-86884c62e11c",
                "type": "text"
            }
        ]
    },
    {
        "category": "Food and Culinary Services",
        "name": "Personal chef services",
        "code": "PC",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What type of cuisine or specific dishes do you want the personal chef to prepare?",
                "identifier": "a36ce0c2-5e48-4240-bfcd-b86de1c7418c",
                "type": "text"
            },
            {
                "question": "Do you have any dietary restrictions or preferences?",
                "identifier": "94da3a0d-7a5a-4e8d-bc3b-d1385f0e9be9",
                "type": "text"
            }
        ]
    },
    {
        "category": "Food and Culinary Services",
        "name": "Home baking and catering",
        "code": "HB",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific baked goods or catering services do you need?",
                "identifier": "c8816fb3-91d6-43da-98df-31798a9298bc",
                "type": "text"
            },
            {
                "question": "What is the date and size of the event (if applicable)?",
                "identifier": "f05a1d56-6aa7-40d9-b1db-75c7413bdc05",
                "type": "text"
            }
        ]
    },
    {
        "category": "Food and Culinary Services",
        "name": "Meal delivery",
        "code": "MD",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What type of meals or cuisine do you want to be delivered?",
                "identifier": "23613256-47d3-43d5-b50f-d8de1418e2d3",
                "type": "text"
            },
            {
                "question": "Do you have any dietary preferences or restrictions for the meals?",
                "identifier": "3c5c49aa-c33c-463b-86de-2d92815d8c98",
                "type": "text"
            }
        ]
    },
    {
        "category": "Events and Entertainment",
        "name": "Home-based photography",
        "code": "HP",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What type of photography services do you require?",
                "identifier": "13f0bc4f-cf6a-42f8-99bb-3a9abba760c3",
                "type": "text"
            },
            {
                "question": "What is the date and location of the event or photo session?",
                "identifier": "59e607f2-8f57-4e9a-b6b9-6d9d0d39a125",
                "type": "text"
            }
        ]
    },
    {
        "category": "Events and Entertainment",
        "name": "Home-based music lessons",
        "code": "HM",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What specific instrument or type of music lessons do you want?",
                "identifier": "5eb419ea-9a63-4b01-88f1-b0076e8ad9ed",
                "type": "text"
            },
            {
                "question": "What is your level of experience in playing the instrument?",
                "identifier": "b19276e2-5a44-40e1-b7a5-1178af65e67a",
                "type": "text"
            }
        ]
    },
    {
        "category": "Events and Entertainment",
        "name": "Magician or entertainer services",
        "code": "ME",
        "type": "primary",
        "active": true,
        "questions": [
            {
                "question": "What type of entertainment or magic show are you interested in?",
                "identifier": "e3d791e1-846a-4a09-818d-36f05599fbc3",
                "type": "text"
            },
            {
                "question": "What is the date and location of the event or performance?",
                "identifier": "31f88256-61bb-4e0d-b89f-76852d2ebe67",
                "type": "text"
            }
        ]
    }
]

export const TRANSPORTATION_SERVICES = [
    {
        "name": "Car Rental",
        "category": "Car Rental",
        "code": "CR",
        "primary": true,
        "active": true,
        "questions": [
            "Planning a road trip and need a vehicle?",
            "Looking for a specific type of car for a special occasion?",
            "Require a temporary replacement vehicle?"
        ]
    },
    {
        "name": "Limousine Service",
        "category": "Premium Transportation",
        "code": "LS",
        "primary": true,
        "active": true,
        "questions": [
            "Celebrating a special event and want to arrive in style?",
            "Need luxurious transportation for a business engagement?",
            "Looking for a comfortable and elegant ride?"
        ]
    },
    {
        "name": "Airport Shuttle",
        "category": "Premium Transportation",
        "code": "AS",
        "primary": true,
        "active": true,
        "questions": [
            "Require transportation to and from the airport?",
            "Traveling in a group and need space for luggage?",
            "Want a cost-effective way to get to the airport?"
        ]
    },
    {
        "name": "Taxi Service",
        "category": "Premium Transportation",
        "code": "TS",
        "primary": true,
        "active": true,
        "questions": [
            "Need a cab to quickly get to your destination?",
            "Prefer a traditional taxi service over app-based options?",
            "Looking for a reliable transportation option during peak hours?"
        ]
    },

    {
        "name": "Standard Delivery",
        "category": "Delivery Service",
        "code": "SD",
        "primary": true,
        "active": true,
        "questions": [
            "Looking for affordable and reliable delivery for everyday items?",
            "Need to send a parcel within a reasonable timeframe?",
            "Want a hassle-free way to receive online orders?"
        ]
    },
    {
        "name": "Express Delivery",
        "category": "Delivery Service",
        "code": "ED",
        "primary": true,
        "active": true,
        "questions": [
            "Need urgent delivery for time-sensitive items?",
            "Want same-day or next-day delivery options?",
            "Looking for speedy and efficient delivery services?"
        ]
    },
    {
        "name": "Grocery Delivery",
        "category": "Delivery Service",
        "code": "GD",
        "primary": true,
        "active": true,
        "questions": [
            "Want groceries delivered to your doorstep?",
            "Prefer to avoid crowded stores and save time?",
            "Looking for a convenient way to shop for groceries?"
        ]
    },
    {
        "name": "Furniture Delivery",
        "category": "Delivery Service",
        "code": "FD",
        "primary": true,
        "active": true,
        "questions": [
            "Need to transport furniture from store to home?",
            "Looking for professionals to handle large item delivery?",
            "Want safe and secure transportation for bulky items?"
        ]
    }
]

export const HEALTH_SERVICES = [
    {
        "category": "Medical Services",
        "name": "Home Health Care",
        "code": "HH",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific services are required for Home Health Care?",
                "identifier": "c60245a6-58d3-45e1-91c4-f89d944fc4f1",
                "answerType": "text"
            },
            {
                "question": "Is there a specific frequency or duration for Home Health Care?",
                "identifier": "a3e5c9d0-462f-4781-9c64-5025019a2325",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Physical Therapy",
        "code": "PT",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What type of physical therapy is required?",
                "identifier": "f63b5ae0-830f-4720-91fc-5b77bdf7e4b7",
                "answerType": "text"
            },
            {
                "question": "Are there any specific conditions or limitations for physical therapy?",
                "identifier": "f3909dd2-e2a7-4730-8c82-37b08439d13e",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Occupational Therapy",
        "code": "OT",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What are the goals or focus areas for Occupational Therapy?",
                "identifier": "beaf87d1-0d35-4f80-8ab2-c93e6f823e7e",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred schedule or frequency for Occupational Therapy?",
                "identifier": "1b111b3b-c8c2-4fbf-b84f-25dd10f92a20",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Speech Therapy",
        "code": "ST",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What speech therapy services are required?",
                "identifier": "46df8b08-8a03-44ce-aa67-1d6c4c5f7f28",
                "answerType": "text"
            },
            {
                "question": "Are there any specific communication or language concerns?",
                "identifier": "beaf87d1-0d35-4f80-8ab2-c93e6f823e7e",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Infusion Therapy",
        "code": "IT",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What type of infusion therapy is required?",
                "identifier": "72a29914-4c43-4014-a35f-76e96af81e31",
                "answerType": "text"
            },
            {
                "question": "Are there any specific medical conditions or requirements for infusion therapy?",
                "identifier": "0f81f871-c513-48f1-8cd5-6f7e4fcf0b29",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Palliative Care",
        "code": "PC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What are the primary goals or focus areas for Palliative Care?",
                "identifier": "e7b679c2-87fb-4091-9365-e727a6fbb1a0",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred schedule or frequency for Palliative Care?",
                "identifier": "0d8c1b82-7d82-45f0-b498-3c4ea4b1b1e2",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Medical Services",
        "name": "Hospice Care",
        "code": "HC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What hospice care services are required?",
                "identifier": "368235cd-8271-4fb7-8cd9-7f34980f60c0",
                "answerType": "text"
            },
            {
                "question": "Is there a specific location or facility preference for Hospice Care?",
                "identifier": "103a5369-8f89-4b0a-afed-3c4d84f75e07",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Mental Health Services",
        "name": "Home-based Counseling/Psychotherapy",
        "code": "HC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What are the main concerns or issues for the counseling/psychotherapy?",
                "identifier": "b9a972df-3f86-4736-92b0-1d65738b662d",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of communication for the counseling/psychotherapy?",
                "identifier": "53e7242c-82b5-4ae1-8f4b-e6e9848326b8",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Mental Health Services",
        "name": "Psychiatric Home Care",
        "code": "PH",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What psychiatric care services are required?",
                "identifier": "7f7487a0-49e9-4d03-bdbd-cc6c4aa5869d",
                "answerType": "text"
            },
            {
                "question": "Is there a specific medical history or condition that requires psychiatric home care?",
                "identifier": "c4eac0f7-33a6-46cc-b957-35f47a9f10c2",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Mental Health Services",
        "name": "Behavioral Therapy",
        "code": "BT",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific behavioral therapy is required?",
                "identifier": "32cfd62d-53e3-41e2-9e21-3a1d0a9d012f",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred duration or frequency for behavioral therapy sessions?",
                "identifier": "0e635b6d-e32d-4213-9d7b-cf9d5241c309",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Mental Health Services",
        "name": "Support Groups",
        "code": "SG",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What type of support group is required?",
                "identifier": "7f98c8c0-76d3-46d5-879f-34e01055b5d7",
                "answerType": "text"
            },
            {
                "question": "Is there a specific focus or topic for the support group?",
                "identifier": "825b648f-7c7b-447e-91a3-ef3d59efc873",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Senior Care Services",
        "name": "Companionship Care",
        "code": "CC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What activities or tasks are required for Companionship Care?",
                "identifier": "1d8b261b-eec1-4ca7-bff1-7e87ee64e019",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred schedule or frequency for Companionship Care?",
                "identifier": "3d787dda-5a3c-454f-93c9-6978b4d09ef3",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Senior Care Services",
        "name": "Personal Care",
        "code": "PC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific personal care services are required?",
                "identifier": "40a920e3-8ff3-45e1-991a-e25c00b5b0a2",
                "answerType": "text"
            },
            {
                "question": "Are there any specific health conditions or considerations for Personal Care?",
                "identifier": "2d8a93bc-6280-4f33-af05-73867e98d35a",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Senior Care Services",
        "name": "Homemaking Services",
        "code": "HS",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What homemaking tasks or services are required?",
                "identifier": "916b1723-9135-4aef-b4c6-78407f76cfa1",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred schedule or frequency for Homemaking Services?",
                "identifier": "269124d2-7b44-4aa3-99f8-d0e2f53900a9",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Senior Care Services",
        "name": "Respite Care",
        "code": "RC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What are the specific care requirements for Respite Care?",
                "identifier": "44d6ea0e-79a1-41ed-aa97-1b2e29f405e9",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred schedule or frequency for Respite Care?",
                "identifier": "5366eef7-8a97-40ac-8680-071587cbcb2f",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Nutrition and Dietetic Services",
        "name": "Home-based Diet Counseling",
        "code": "DC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific dietary requirements or goals are there for Diet Counseling?",
                "identifier": "ac6df912-e6e9-4f0d-8b02-c0faffa84328",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of communication for Diet Counseling?",
                "identifier": "f1de83d2-63e7-4d5a-b99f-44b7f5a2736e",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Nutrition and Dietetic Services",
        "name": "Nutritional Support",
        "code": "NS",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific nutritional support is required?",
                "identifier": "e46c0027-936e-470e-8d63-07bc2c3cbe24",
                "answerType": "text"
            },
            {
                "question": "Is there a specific medical condition or requirement for Nutritional Support?",
                "identifier": "9d1a129a-6519-45d3-a9b0-5cb5b956af29",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Maternal and Pediatric Care",
        "name": "Prenatal and Postnatal Care",
        "code": "PP",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific prenatal and postnatal care services are required?",
                "identifier": "e8f26d2e-bde6-4144-8a85-1d51b6d7e1aa",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of care or delivery for Prenatal and Postnatal Care?",
                "identifier": "5c26cf2e-534a-4b25-9153-6181151a2c0b",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Maternal and Pediatric Care",
        "name": "Pediatric Home Care",
        "code": "PH",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific pediatric care services are required?",
                "identifier": "6e59b080-ef34-4cc1-a8bc-97c273e14271",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of care or treatment for Pediatric Home Care?",
                "identifier": "07d16535-ee97-4db7-9a7d-4a2d4313a472",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Alternative and Holistic Services",
        "name": "Home-based Yoga and Meditation",
        "code": "YM",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific type of yoga and meditation practices are required?",
                "identifier": "b062bf2a-68e8-4a42-81b3-1624e5736aa5",
                "answerType": "text"
            },
            {
                "question": "Are there any physical or health considerations for practicing yoga and meditation at home?",
                "identifier": "7c40a0d2-e2a5-4086-a313-3e5d6c77a805",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Alternative and Holistic Services",
        "name": "Massage Therapy",
        "code": "MT",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific areas or concerns require massage therapy?",
                "identifier": "28d31e7e-9d0d-47b1-830c-0ad3c4db2d34",
                "answerType": "text"
            },
            {
                "question": "Are there any medical conditions or contraindications for massage therapy?",
                "identifier": "83131e3b-24e5-4c21-891b-52b82cb86c58",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Alternative and Holistic Services",
        "name": "Acupuncture",
        "code": "AC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific health concerns or conditions require acupuncture?",
                "identifier": "8f4309de-0669-4a4b-aa0a-953f0a7e3b84",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred frequency or duration for acupuncture sessions?",
                "identifier": "c642c074-ea09-4e32-a01a-24fb7456ee13",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Alternative and Holistic Services",
        "name": "Herbal and Nutritional Consultations",
        "code": "HN",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific health goals or concerns require herbal and nutritional consultations?",
                "identifier": "97b5c587-1c6b-4f02-8f1e-e9953a37c352",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of consultation or communication for herbal and nutritional advice?",
                "identifier": "f9f916b3-6e79-4ea5-8255-9f49c07d65ac",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Health Monitoring and Technology Services",
        "name": "Remote Health Monitoring",
        "code": "RM",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific health parameters or conditions require remote health monitoring?",
                "identifier": "f86c6e11-124c-450e-9675-7a6d509f9ee1",
                "answerType": "text"
            },
            {
                "question": "Are there any specific devices or technologies needed for remote health monitoring?",
                "identifier": "86a38e3d-bbbd-45d5-9e9a-4c7d9e914548",
                "answerType": "text"
            }
        ]
    },
    {
        "category": "Health Monitoring and Technology Services",
        "name": "Telemedicine Consultations",
        "code": "TC",
        "primary": true,
        "active": true,
        "questions": [
            {
                "question": "What specific health concerns or conditions require telemedicine consultations?",
                "identifier": "d17f43b5-e4e6-48b1-aae4-8ad60d308dc0",
                "answerType": "text"
            },
            {
                "question": "Is there a preferred mode of communication or platform for telemedicine consultations?",
                "identifier": "dc31d9d1-d50d-430a-b81c-20b3ea70e106",
                "answerType": "text"
            }
        ]
    }
]

export const COMMERCIAL_AUTO = ['Heavy Duty', 'Super Duty', "Specialized", 'assistance']

export const PERSONAL_AUTO = ['assistance', 'Motorcycle', "Specialized", 'Light Duty', 'Medium Duty']

export const ALL_AUTO = intersection(COMMERCIAL_AUTO, PERSONAL_AUTO)

export const CATEGORY_OPTION = [
    {
        group: 'auto',
        classify: keys(groupBy(orderBy(AUTO_SERVICES, ['category'], ['asc']), 'category')).map((key) => ({
            category: startCase(key),
            subClassify: sortBy(flatMap(groupBy(AUTO_SERVICES, 'category')[key], 'name')),
        })),
    },
    {
        group: 'home',
        classify: keys(groupBy(orderBy(HOME_SERVICES, ['order'], ['asc']), 'category')).map((key) => ({
            category: startCase(key),
            subClassify: sortBy(flatMap(groupBy(HOME_SERVICES, 'category')[key], 'name')),
        })),
    },
    {
        group: 'health and wellness',
        classify: keys(groupBy(orderBy(HEALTH_SERVICES, ['order'], ['asc']), 'category')).map((key) => ({
            category: startCase(key),
            subClassify: sortBy(flatMap(groupBy(HEALTH_SERVICES, 'category')[key], 'name')),
        })),
    },
    {
        group: 'transportation',
        classify: keys(groupBy(orderBy(TRANSPORTATION_SERVICES, ['order'], ['asc']), 'category')).map((key) => ({
            category: startCase(key),
            subClassify: sortBy(flatMap(groupBy(TRANSPORTATION_SERVICES, 'category')[key], 'name')),
        })),
    },
];

export const AUTO_KEY = 'auto';

export const HOME = 'home';

export const HEALTH_AND_WELLNESS = 'health';

export const TRANSPORTATION = 'transportation';

export const DOLOOMA_COMPANY_TYPES = {
    provider: {
        value: 'provider',
        label: 'I am a Service Provider',
        description: 'An individual professional, freelancer, or a business that offer services. You will use Dolooma to list your services, manage your schedules, communicate your clients, and manage the order fulfillment process for the services you offer.'
    },
    business: {
        value: 'b2b',
        label: 'I am a Business',
        description: 'A business in need of reliable services to enhance its operations. You will use Dolooma to utilize a selection of skilled service providers to streamline your bookings, manage contracts and pricing, and ensure your operations run smoothly.'
    },
    businessToConsumer: {
        value: 'b2b2c',
        label: 'I am a Business support my Customers',
        description: 'A business that is managing a range of services for your customers. You will use Dolooma to integrate into your processes and business models to enhance your product offering, manage contracts and pricing, and seamless experience for you customers.'
    },
    consumer: {
        value: 'c',
        label: 'I am an individual Customer',
        description: 'A single person, maybe even a family, that is in need of various services for their personal needs. You will use Dolooma to get access to a broad list of top-notch services and easily find and book the services you desire.'
    }
};

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
    audience: process.env.REACT_APP_HOST,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = "pk.eyJ1IjoidG1vb3JlNzgiLCJhIjoiY2w4NXYybjFuMDl3NTNwczkxeXNhODR0ZSJ9.r1t7iQ0-jLN2aSSY3hKkGw";
// export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.welcome; // as '/dashboard/app'
export const CUSTOMER_PATH_AFTER_LOGIN = PATH_DASHBOARD.general.welcome; // as '/customer/app'
export const DRIVER_PATH_AFTER_LOGIN = PATH_DASHBOARD.general.welcome; // as '/driver/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

export const FLAT_FILE_CONFIG = {
    ACCESS_KEY: process.env.REACT_APP_FLAT_FILE_ACCESS_KEY,
    SHARED_ACCESS_KEY: process.env.REACT_APP_FLAT_FILE_SHARED_ACCESS_KEY,
    PORTAL_PRIVATE_KEY: process.env.REACT_APP_FLAT_FILE_PORTAL_PRIVATE_KEY
}


export const dow = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const PRICE_ADJUSTMENT_TYPES = ['time', 'inclement weather', 'summer', 'winter', 'spring', 'fall', 'custom date range', 'high season', 'low season']

export const PAYROLL_ADJUSTMENT_TYPES = ['job volume', 'time'];//TODO: These will be introduced at a later date, 'inclement weather', 'summer', 'winter', 'spring', 'fall', 'custom date range', 'high season', 'low season']

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColorPresets: 'default',
    themeLayout: 'horizontal',
    themeStretch: true,
};
export const CONTRACT_TAGS = []
export const SOCIAL_LINKS = [
    {
        value: 'facebookLink',
        socialColor: '#1877F2',
        iconName: 'eva:facebook-fill',
        icon: <Iconify icon={'eva:facebook-fill'} width={24} height={24}/>,
    },
    {
        value: 'instagramLink',
        socialColor: '#E02D69',
        iconName: 'ant-design:instagram-filled',
        icon: <Iconify icon={'ant-design:instagram-filled'} width={24} height={24}/>,
    },
    {
        value: 'youtubeLink',
        socialColor: '#E02D69',
        iconName: 'uil:youtube',
        icon: <Iconify icon={'uil:youtube'} width={24} height={24}/>,
    },
    {
        value: 'linkedinLink',
        socialColor: '#007EBB',
        iconName: 'eva:linkedin-fill',
        icon: <Iconify icon={'eva:linkedin-fill'} width={24} height={24}/>,
    },
    {
        value: 'twitterLink',
        socialColor: '#00AAEC',
        iconName: 'pajamas:twitter',
        icon: <Iconify icon={'pajamas:twitter'} width={24} height={24}/>,
    },
];

export const roleLabels =
    {
        god: 'God',
        doloomaSuperUser: 'Dolooma Super User',
        doloomaSupport: 'Dolooma Support',
        providerSuperUser: 'Provider Super User',
        providerAdmin: 'Provider Admin',
        provider: 'Provider',
        consumerSuperUser: 'Consumer Super User',
        consumerAdmin: 'Consumer Admin',
        consumer: 'Consumer'
    };

export const packageLabels =
    {
        god: 'God'
    };

export const LANGS = [
    {
        label: 'English',
        currencyCode: 'USD',
        value: 'en-us',
        systemValue: enUS,
        icon: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_flag_en.svg`,
    },
    {
        label: 'French',
        currencyCode: 'CAD',
        value: 'fr-ca',
        systemValue: frFR,
        icon: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_flag_ca.svg`,
    },
    {
        label: 'Spanish (Mexico)',
        currencyCode: 'MXN',
        value: 'mx-es',
        systemValue: esES,
        icon: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/flags/mx.svg`,
    },
    {
        label: 'Spanish (Cuba)',
        currencyCode: 'CUP',
        value: 'cu-es',
        systemValue: esES,
        icon: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/flags/cu.svg`,
    },
    // {
    //     label: 'German',
    //     currencyCode: 'EUR',
    //     value: 'de',
    //     systemValue: deDE,
    //     icon: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_flag_de.svg`,
    // },
];

export const CURRENCIES = {
    "MXN": {
        "name": "Mexican Peso",
        "delimiters": {
            "thousands": ' ',
            "decimal": ','
        },
        "iso": {
            "code": "MXN",
            "num": "484"
        },
        "symbol": {
            "default": {
                "display": "$",
                "code": "&#x24;"
            },
            "native": {
                "display": "$",
                "code": "&#x24;"
            }
        },
        "units": {
            "decimal": 2,
            "sub": 100,
            "name": {
                "major": "Dollar",
                "minor": "Cent"
            }
        },
        "format": "-$###.###.###,##"
    },
    "CAD": {
        "name": "Canadian Dollar",
        "delimiters": {
            "thousands": ' ',
            "decimal": ','
        },
        "iso": {
            "code": "CAD",
            "num": "124"
        },
        "symbol": {
            "default": {
                "display": "CA$",
                "code": "CA&#x24;"
            },
            "native": {
                "display": "$",
                "code": "&#x24;"
            }
        },
        "units": {
            "decimal": 2,
            "sub": 100,
            "name": {
                "major": "Dollar",
                "minor": "Cent"
            }
        },
        "format": "-CA$###.###.###,##"
    },
    "EUR": {
        "name": "Euro",
        "delimiters": {
            "thousands": ',',
            "decimal": '.'
        },
        "iso": {
            "code": "EUR",
            "num": "978"
        },
        "symbol": {
            "default": {
                "display": "€",
                "code": "&#x20ac;"
            },
            "native": null
        },
        "units": {
            "decimal": 2,
            "sub": 100,
            "name": {
                "major": "Euro",
                "minor": "Cent"
            }
        },
        "format": "-€###.###.###,##"
    },
    "USD": {
        "delimiters": {
            "thousands": ',',
            "decimal": '.'
        },
        "name": "US Dollar",
        "iso": {
            "code": "USD",
            "num": "840"
        },
        "symbol": {
            "default": {
                "display": "$",
                "code": "&#x24;"
            },
            "native": null
        },
        "units": {
            "decimal": 2,
            "sub": 100,
            "name": {
                "major": "Dollar",
                "minor": "Cent"
            }
        },
        "format": "-$###,###,###.##"
    },
    "CUP": {
        "delimiters": {
            "thousands": '.',
            "decimal": ','
        },
        "name": "Cuban Peso",
        "iso": {
            "code": "CUP",
            "num": "192"
        },
        "symbol": {
            "default": {
                "display": "$",
                "code": "&#x20b1;"
            },
            "native": null
        },
        "units": {
            "decimal": 2,
            "sub": 100,
            "name": {
                "major": "Peso",
                "minor": "Centavo"
            }
        },
        "format": "-$###.###,##"
    }
}

export const STATES_PROVINCES = [
    {
        "name": "United States",
        "abbreviation": "US",
        "states": [
            {
                "name": "Alabama",
                "abbreviation": "AL"
            },
            {
                "name": "Alaska",
                "abbreviation": "AK"
            },
            {
                "name": "American Samoa",
                "abbreviation": "AS"
            },
            {
                "name": "Arizona",
                "abbreviation": "AZ"
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR"
            },
            {
                "name": "California",
                "abbreviation": "CA"
            },
            {
                "name": "Colorado",
                "abbreviation": "CO"
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT"
            },
            {
                "name": "Delaware",
                "abbreviation": "DE"
            },
            {
                "name": "District Of Columbia",
                "abbreviation": "DC"
            },
            {
                "name": "Federated States Of Micronesia",
                "abbreviation": "FM"
            },
            {
                "name": "Florida",
                "abbreviation": "FL"
            },
            {
                "name": "Georgia",
                "abbreviation": "GA"
            },
            {
                "name": "Guam",
                "abbreviation": "GU"
            },
            {
                "name": "Hawaii",
                "abbreviation": "HI"
            },
            {
                "name": "Idaho",
                "abbreviation": "ID"
            },
            {
                "name": "Illinois",
                "abbreviation": "IL"
            },
            {
                "name": "Indiana",
                "abbreviation": "IN"
            },
            {
                "name": "Iowa",
                "abbreviation": "IA"
            },
            {
                "name": "Kansas",
                "abbreviation": "KS"
            },
            {
                "name": "Kentucky",
                "abbreviation": "KY"
            },
            {
                "name": "Louisiana",
                "abbreviation": "LA"
            },
            {
                "name": "Maine",
                "abbreviation": "ME"
            },
            {
                "name": "Marshall Islands",
                "abbreviation": "MH"
            },
            {
                "name": "Maryland",
                "abbreviation": "MD"
            },
            {
                "name": "Massachusetts",
                "abbreviation": "MA"
            },
            {
                "name": "Michigan",
                "abbreviation": "MI"
            },
            {
                "name": "Minnesota",
                "abbreviation": "MN"
            },
            {
                "name": "Mississippi",
                "abbreviation": "MS"
            },
            {
                "name": "Missouri",
                "abbreviation": "MO"
            },
            {
                "name": "Montana",
                "abbreviation": "MT"
            },
            {
                "name": "Nebraska",
                "abbreviation": "NE"
            },
            {
                "name": "Nevada",
                "abbreviation": "NV"
            },
            {
                "name": "New Hampshire",
                "abbreviation": "NH"
            },
            {
                "name": "New Jersey",
                "abbreviation": "NJ"
            },
            {
                "name": "New Mexico",
                "abbreviation": "NM"
            },
            {
                "name": "New York",
                "abbreviation": "NY"
            },
            {
                "name": "North Carolina",
                "abbreviation": "NC"
            },
            {
                "name": "North Dakota",
                "abbreviation": "ND"
            },
            {
                "name": "Northern Mariana Islands",
                "abbreviation": "MP"
            },
            {
                "name": "Ohio",
                "abbreviation": "OH"
            },
            {
                "name": "Oklahoma",
                "abbreviation": "OK"
            },
            {
                "name": "Oregon",
                "abbreviation": "OR"
            },
            {
                "name": "Palau",
                "abbreviation": "PW"
            },
            {
                "name": "Pennsylvania",
                "abbreviation": "PA"
            },
            {
                "name": "Puerto Rico",
                "abbreviation": "PR"
            },
            {
                "name": "Rhode Island",
                "abbreviation": "RI"
            },
            {
                "name": "South Carolina",
                "abbreviation": "SC"
            },
            {
                "name": "South Dakota",
                "abbreviation": "SD"
            },
            {
                "name": "Tennessee",
                "abbreviation": "TN"
            },
            {
                "name": "Texas",
                "abbreviation": "TX"
            },
            {
                "name": "Utah",
                "abbreviation": "UT"
            },
            {
                "name": "Vermont",
                "abbreviation": "VT"
            },
            {
                "name": "Virgin Islands",
                "abbreviation": "VI"
            },
            {
                "name": "Virginia",
                "abbreviation": "VA"
            },
            {
                "name": "Washington",
                "abbreviation": "WA"
            },
            {
                "name": "West Virginia",
                "abbreviation": "WV"
            },
            {
                "name": "Wisconsin",
                "abbreviation": "WI"
            },
            {
                "name": "Wyoming",
                "abbreviation": "WY"
            }
        ]
    },
    {
        "name": "Canada",
        "abbreviation": "CA",
        "states": [
            {
                "name": "Alberta",
                "abbreviation": "AB"
            },
            {
                "name": "British Columbia",
                "abbreviation": "BC"
            },
            {
                "name": "Manitoba",
                "abbreviation": "MB"
            },
            {
                "name": "New Brunswick",
                "abbreviation": "NB"
            },
            {
                "name": "Newfoundland and Labrador",
                "abbreviation": "NL"
            },
            {
                "name": "Northwest Territories",
                "abbreviation": "NT"
            },
            {
                "name": "Nova Scotia",
                "abbreviation": "NS"
            },
            {
                "name": "Nunavut",
                "abbreviation": "NU"
            },
            {
                "name": "Ontario",
                "abbreviation": "ON"
            },
            {
                "name": "Prince Edward Island",
                "abbreviation": "PE"
            },
            {
                "name": "Quebec",
                "abbreviation": "QC"
            },
            {
                "name": "Saskatchewan",
                "abbreviation": "SK"
            },
            {
                "name": "Yukon Territory",
                "abbreviation": "YT"
            }
        ]
    }
]

export const FAQ = [
    {
        id: 1,
        localeId: 'whyDolooma',
        heading: `Why Dolooma Software?`,
        detail: 'Detail'
    },
    {
        id: 2,
        localeId: 'howSecure',
        heading: `How secure is your platform?`,
        detail: 'Detail'
    },
    {
        id: 3,
        localeId: 'whereCanI',
        heading: `Where can I get the software users manual?`,
        detail: 'Detail'
    },
    {
        id: 4,
        localeId: 'whatIsPricing',
        heading: `What is Dolooma’s pricing model?`,
        detail: 'Detail'
    },
    {
        id: 5,
        localeId: 'doesDolooma',
        heading: `Does Dolooma integrate with our solutions?`,
        detail: 'Detail'
    },
    {
        id: 6,
        localeId: 'howLong',
        heading: `How long does it take Dolooma to implement the solution?`,
        detail: 'Detail'
    },
    {
        id: 7,
        localeId: 'whatIsPolicy',
        heading: `What is your data policy?`,
        detail: 'Detail'
    },
    {
        id: 8,
        localeId: 'whatDoYouMean',
        heading: `What do you mean you have configurable workflows?`,
        detail: 'Detail'
    },
    {
        id: 9,
        localeId: 'doYouHave',
        heading: `Do you have Single Sign-on (SSO)?`,
        detail: 'Detail'
    },
    {
        id: 10,
        localeId: 'howDoes',
        heading: `How does Dolooma Software mange our privacy and user privacy?`,
        detail: 'Detail'
    },
    {
        id: 11,
        localeId: 'whatIsTech',
        heading: `What is your technology stack?`,
        detail: 'Detail'
    }
]

export const INTEGRATION_CARDS = [
    {
        header: 'Accounting',
        cards: [
            {
                popup: true,
                key: 'quickBooks',
                icon: 'simple-icons:quickbooks',
                title: 'QuickBooks',
                description: 'Power your business with software for accounting, payroll and more. Connect with experts for help staying organized and getting ready for tax time.'
            },
            {
                popup: true,
                key: 'xero',
                icon: 'cib:xero',
                title: 'Xero',
                description: 'Xero accounting software is made for small businesses. Automate admin and work from anywhere, anytime.'
            },
            {
                popup: true,
                key: 'freshBooks',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/freshbooks.png`,
                title: 'FreshBooks',
                description: 'Accounting Software Built for Business Owners & Accountants.'
            },
            {
                popup: true,
                key: 'sageIntacct',
                icon: 'simple-icons:sage',
                title: 'Sage Intacct',
                description: 'Sage Intacct\'s core financials automate your most important processes, reduce your reliance on spreadsheets, and give you greater visibility into your real-time business performance.'
            },
            {
                popup: true,
                key: 'netSuite',
                icon: 'cib:oracle-netsuite',
                title: 'NetSuite',
                description: 'One Unified, Cloud Business Management Suite'
            },
            {
                popup: true,
                key: 'sapBusinessOne',
                icon: 'simple-icons:sap',
                title: 'SAP Business One',
                description: 'A robust, flexible and affordable way to streamline your entire business, SAP Business One is an ERP system that integrates your key business functions.'
            },
            {
                popup: true,
                key: 'myob',
                icon: 'simple-icons:myob',
                title: 'MYOB',
                description: 'Our business management software has been rated best in class by small business owners across Australia and New Zealand. It’s the all-in-one platform for all your business needs.'
            },
        ]
    }, {
        header: 'Payments',
        cards: [
            {
                popup: true,
                key: 'stripe',
                icon: 'ph:stripe-logo-fill',
                title: 'Stripe',
                description: 'Secure way to accept credit and debit cards online. Allows bank transfers as well'
            },
            {
                popup: true,
                key: 'square',
                icon: 'bx:checkbox-square',
                title: 'Square',
                description: 'Accept credit cards online, securely, with Square,'
            },
            {
                popup: true,
                key: 'paypal',
                icon: 'bxl:paypal',
                title: 'Pay Pal',
                description: 'Allows secure payments via credit and debit cards, bank accounts, as well as account balances'
            },
            {
                popup: true,
                key: 'venmo',
                icon: 'ion:logo-venmo',
                title: 'Venmo',
                description: 'Securely send money direct to accounts. No cards needed.'
            },
            {
                popup: true,
                key: 'ach',
                icon: 'fluent:building-bank-toolbox-20-regular',
                title: 'ACH',
                description: 'Utilize the digital financial network that is used for electronic payments and money transfers between banks or financial institutions in the U.S.'
            }
        ]
    }, {
        header: 'Productivity',
        cards: [
            {
                popup: true,
                key: 'teamwork',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/teamwork.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/teamwork_dark.png`,
                title: 'Teamwork',
                description: 'Best Project Management Tool — Run Your Projects More Efficiently & Profitably with Teamwork.com Project Management Tool.'
            }
        ]
    },
    {
        header: 'Customer Experience',
        cards: [
            {
                popup: true,
                key: 'medallia',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/medallia.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/medallia_dark.png`,
                title: 'Medallia',
                description: 'Experience Mgmt Solutions — Get a Real-Time View of Customer and Employee Experience with Medallia Experience Cloud. Medallia Supports B2C, B2B, Employee and Product Experience.'
            }
        ]
    },
    {
        header: 'HR',
        cards: [
            {
                popup: true,
                key: 'adp',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/adp.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/adp_dark.png`,
                title: 'ADP',
                description: 'Over 1 Million Companies Utilize Our Fast & Easy Payroll. All-In-One Payroll Solutions Designed To Help Your Company Grow.'
            }
        ]
    },
    {
        header: 'Compliance',
        cards: [
            {
                popup: true,
                key: 'pruuvn',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/pruuvn.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/pruuvn_dark.png`,
                title: 'Pruuvn',
                description: 'Pruuvn is an all-in-one contractor management platform that helps you find and retain verified gig workers quickly and easily.'
            }
        ]
    },
    {
        header: 'Motor Clubs',
        cards: [
            {
                popup: true,
                key: 'agero',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/agero.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/agero_dark.png`,
                title: 'Agero',
                description: 'Connect with a motor club to support your business management.'
            },
            {
                popup: true,
                key: 'allstate',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/allstate.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/allstate_dark.png`,
                title: 'AllState',
                description: 'Connect with a motor club to support your business management.'
            },
            {
                popup: true,
                key: 'nsd',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/nsd.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/nsd_dark.png`,
                title: 'Nation Safe Drivers (NSD)',
                description: 'Connect with a motor club to support your business management.'
            },
            {
                popup: true,
                key: 'roadsideProtect',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/roadside_protect.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/roadside_protect_dark.png`,
                title: 'Roadside Protect',
                description: 'Connect with a motor club to support your business management.'
            },
            {
                popup: true,
                key: 'honk',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/honk.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/honk_dark.png`,
                title: 'Honk',
                description: 'Connect with a motor club to support your business manaegment.'
            },
            {
                popup: true,
                key: 'urgently',
                light: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/urgently.png`,
                dark: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/urgently_dark.png`,
                title: 'Urgent.ly',
                description: 'Connect with a motor club to support your business management.'
            },
            {
                popup: true,
                key: 'aaa',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/aaa.png`,
                title: 'AAA',
                description: 'Connect with AAA to support your business management.'
            }
        ]
    },
    {
        header: 'logistics & dispatching',
        cards: [
            {
                popup: true,
                key: 'traxero',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/traxero.png`,
                title: 'Traxero',
                description: 'Custom integrated solutions ideal for your business.'
            },
            {
                popup: true,
                key: 'towbook',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/towbook.png`,
                title: 'Towbook',
                description: 'Take full advantage of cloud-based towing software.'
            },
            {
                popup: true,
                key: 'eroad',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/EROAD.png`,
                title: 'EROAD',
                description: 'Leverage the power of our fleet performance management platform built for the Transportation, Food and Beverage, Construction, Waste and Recycling industries.'
            },
        ]
    },
    {
        header: 'Vendor Partners',
        cards: [
            {
                popup: true,
                key: 'walmart',
                icon: 'tabler:brand-walmart',
                title: 'Walmart',
                description: 'Save Money And Time With A Walmart+ Membership. Enjoy Low Prices, Free Delivery And More! Easy Online Shopping. Get it When & Where You Want it.'
            },
            {
                popup: true,
                key: 'autoZone',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/auto-zone.png`,
                title: 'Auto Zone',
                description: 'Shop top-quality auto parts at AutoZone. Your go-to source for car and truck parts, DIY repair advice, and Free Next Day Delivery.'
            },
            {
                popup: true,
                key: 'pepBoys',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/pepboys.jpeg`,
                title: 'Pep Boys',
                description: 'Get an oil change, auto repair, or shop tires at Pep Boys. Open seven days a week. Book an appointment with our expert technicians!'
            },
            {
                popup: true,
                key: 'discountTire',
                image: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/discounttire.jpeg`,
                title: 'Discount Tire',
                description: 'Shop and order new tires and wheels online at the best prices with our famous customer service.'
            },
            {
                popup: true,
                key: 'custom',
                icon: 'mdi:webhook',
                title: 'Custom',
                description: 'Enable a customer webhook to integrate your third party vendor of your choice.'
            }
        ]
    },
    {
        header: 'other',
        cards: [
            {
                path: PATH_RESOURCE_CENTER.integration.view('webhooks'),
                icon: 'mdi:webhook',
                title: 'webhooks',
                key: 'webhooks',
                description: 'Utilize secure integrations via webhooks to be notified about events in your Dolooma account'
            },
            {
                path: PATH_RESOURCE_CENTER.integration.view('sftp'),
                icon: 'arcticons:primitiveftpd',
                title: 'sftp',
                key: 'sftps',
                description: 'Use secure file transfer to send and receive batch data Dolooma account'
            },
            {
                path: PATH_RESOURCE_CENTER.integration.view('api'),
                icon: 'ant-design:api-twotone',
                title: 'api',
                key: 'api',
                description: 'Automate integrations to send and receive data.'
            }
        ]
    }
]

export const FILE_TYPES = ["tax", "contract", "general", "license", "other"];

export const ALERT_TYPES = {
    TEST: 'test',
    JOB_REQUEST_ACCEPT: 'job_request_accept',
    CONTRACT_CREATED: 'contract_created',
    CONTRACT_UPDATED: 'contract_updated',
    CONTRACT_SIGNED: 'contract_signed',
    CONTRACT_COMPLETED: 'contract_completed',
    CONTRACT_DECLINED: 'contract_declined',
    NEW_BID: 'new_bid',
    OFFER_ACCEPTED: 'offer_accepted',
    BID_UPDATED: 'bid_updated',
    JOB_REQUEST: 'job_request',
    ORDER_PLACED: 'order_placed',
    FRIEND_INTERACTIVE: 'friend_interactive',
    DOCUMENT_SIGNED: 'document_signed',
    DOCUMENT_IMPORTED: 'document_imported',
    CHAT_MESSAGE: 'chat_message',
    CHAT_PARTICIPANT: 'chat_participant',
    MAIL: 'mail',
    ORDER_SHIPPED: 'order_shipped',
    SUPPORT_REQUEST: 'support_request',
}

export const COMMISSION_PAY_TYPES = ["commissionOnly", "hourlyWithCommission", "salaryWithCommission"];
export const STANDARD_PAY_TYPES = ["hourly", "salary", "hourlyWithCommission", "salaryWithCommission"];
export const OVERTIME_PAY_TYPES = ["hourly", "hourlyWithCommission"];
export const CLOCK_IN_PAY_TYPES = ["hourly", "hourlyWithCommission"];

export const ALEX_CONTEXTS = {
    JOB_REQUEST_ACCEPT: 'job_request_accept',
    JOB_REQUEST: 'job_request',
    ASSISTANT: 'assistant',
    FRIEND_INTERACTIVE: 'friend_interactive',
    DOCUMENT_SIGNED: 'document_signed',
    CHAT_MESSAGE: 'chat_message',
    MAIL: 'mail',
    ORDER_SHIPPED: 'order_shipped',
    SUPPORT_REQUEST: 'support_request',
}

export const ALERT_TYPES_PATHS = {
    'order_placed': PATH_OPERATIONS_CENTER.chat.root,
    'friend_interactive': PATH_OPERATIONS_CENTER.chat.root,
    'document_signed': PATH_OPERATIONS_CENTER.chat.root,
    'chat_message': PATH_OPERATIONS_CENTER.chat.view,
    'new_chat': PATH_OPERATIONS_CENTER.chat.view,
    'mail': PATH_OPERATIONS_CENTER.chat.root,
    'order_shipped': PATH_OPERATIONS_CENTER.chat.root,
    'support_request': PATH_RESOURCE_CENTER.request.root
}

const authors = {
    trevor: {
        name: 'Trevor Moore',
        role: 'Chief Innovation Officer',
        about: 'A highly experienced technology leader with proven expertise to drive strategic initiatives and facilitate business growth through innovative, cost-effective solutions.',
        quotes: 'Continually seeking the opportunity to create unparalleled customer, organizational, individual, and team experiences with an unwavering passion that goes beyond what people expect.',
        avatarUrl: 'https://staticassetsdolooma.blob.core.windows.net/web/assets/images/members/Trevor.png'
    },
    rajiv: {
        name: 'Rajiv Amar',
        role: 'Chief Executive Officer',
        about: 'A Senior Executive and Change Agent with 27 years’ R&D leadership and management experience in software development. As an accomplished technical leader, Rajiv has a decorated career converting vision into innovative solutions with attention towards change management and achieving business objectives.',
        quotes: 'I optimize emerging and existing technologies to achieve revenue growth, competitive advantage and enhanced organizational effectiveness',
        avatarUrl: 'https://staticassetsdolooma.blob.core.windows.net/web/assets/images/members/Rajiv.png'
    },
    david: {
        name: 'David Owen',
        role: 'General Counsel',
        about: 'Experienced corporate attorney with 20+ years in automotive, mobility, and consumer assistance solutions. Skilled in galvanizing teams, delivering projects on time and within budget, and communicating with diverse audiences. Recognized for expediting processes, connecting complex data, and transforming ideas into action.',
        quotes: 'I help clients optimize contracting strategy and resolve mission-critical compliance issues / hands-on problem solver with deep experience counseling c-suite strategists.',
        avatarUrl: 'https://staticassetsdolooma.blob.core.windows.net/web/assets/images/members/David.png'
    }
}

export const posts = [
    {
        id: "a7b716b4-002f-401a-ac24-68454294a2c2",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.navigating-digital-frontier',
        author: authors.trevor,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/navigating-digital-frontier.png`,
        content: 5,
        tags: 4,
    }, {
        id: "686da3eb-b248-4f23-86e6-7ab693f65335",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.last-mile-service',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/last-mile-service.png`,
        content: 4,
        tags: 3,
    }, {
        id: "686da3eb-b248-4f23-86e6-7ab693f65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.state-regulations',
        author: authors.david,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/state-regulations.png`,
        content: 12,
        tags: 2,
    }, {
        id: "686da3eb-f248-4f23-86e6-7ab695f65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.state-regulations-2',
        author: authors.david,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/state-regulations.png`,
        content: 5,
        tags: 5,
    }, {
        id: "3ff280e2-0983-4e26-bb44-b9d34a2726fa",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.simplify-registration',
        author: authors.trevor,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/simplify-registration.png`,
        content: 6,
        tags: 5,
    }, {
        id: "686dd3ed-f248-4f23-86e6-7ab695f65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.driving-in-harmony',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/driving-in-harmony.png`,
        content: 13,
        tags: 5,
    }, {
        id: "886dd3ed-f248-4f23-86e6-7ab695f65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.nation-vs-state',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/nation-vs-state.png`,
        content: 4,
        tags: 5,
    }, {
        id: "686da3eb-b248-4f23-86e6-7ab693f653d5",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.revolutionize-the-industry',
        author: authors.trevor,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/revolutionize-the-industry.png`,
        content: 7,
        tags: 5,
    }, {
        id: "886ff3ed-f248-4b23-86d6-7ab695f65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.state-compliance',
        author: authors.david,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/state-compliance.png`,
        content: 4,
        tags: 4,
    }, {
        id: "886ff3ed-f248-4f23-86d6-7cc695d65635",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.contract-management',
        author: authors.david,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/contract-management.png`,
        content: 8,
        tags: 7,
    }, {
        id: "0855bd0d-583e-452e-83cd-1519714144c9",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.key-to-control',
        author: authors.trevor,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/key-to-control.png`,
        content: 8,
        tags: 6,
    }, {
        id: "886ff3ed-f248-4f23-86d6-7cc695c63735",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.road-to-revenue',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/road-to-revenue.png`,
        content: 8,
        tags: 5,
    }, {
        id: "446dd3ed-f248-4f23-86d6-7cc695c63735",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.contract-adherence',
        author: authors.david,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/contract-adherence.png`,
        content: 8,
        tags: 4,
    }, {
        id: "6b01269b-509c-44f6-b1f4-d27e1c52726e",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.security-matters',
        author: authors.trevor,
        createdDate: new Date(),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/security-matters.png`,
        content: 8,
        tags: 8,
    }, {
        id: "446dd3ed-f248-4f23-86d6-7ff695f63747",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.new-revenue',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/new-revenue.png`,
        content: 8,
        tags: 5,
    }, {
        id: "31bd3ca-f248-4f23-86d6-7ff695f63747",
        duration: `${Math.floor(Math.random() * (15 - 5 + 1) + 5)} minute read`,
        key: 'pages.blogs.blogs.accelerate-cash-flow',
        author: authors.rajiv,
        createdDate: (new Date()).setDate(1 - Math.floor(Math.random() * (45 - 5 + 1) + 5)),
        coverUrl: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/blog/accelerate-cash-flow.png`,
        content: 8,
        tags: 4,
    }
];

export const _tags = [
    "transportation",
    "payment methods",
    "digital era",
    "technology",
    "logistics",
    "roadside assistance",
    "operational efficiency",
    "customer satisfaction",
    "cost-effectiveness",
    "registration process",
    "user experience",
    "Single Sign-On",
    "convenience",
    "security",
    "data security",
    "privacy",
    "trust",
    "regulatory compliance",
    "business continuity",
    "intellectual property",
    "customer confidence",
    "competitive advantage",
    "innovation",
    "home services",
    "Last Mile Delivery",
    "E-commerce Trends",
    "Compliance",
    "Regulations",
    "State Regulations",
    "Safety",
    "Fleet Management",
    "Federal Regulations",
    "Efficiency",
    "National Vehicle Transportation",
    "Within-State Vehicle Transportation",
    "Vehicle Delivery",
    "Transportation Providers",
    "Contract Management",
    "Partnerships",
    "Risk Mitigation",
    "Operational Efficiency",
    "Technology",
    "Contract Adherence",
    "Revenue Growth",
    "Efficiency",
    "Client Relationships",
    "Market Positioning",
    "Dolooma Platform",
    "Transportation Services",
    "Ecosystem",
    "Payment Processing",
    "Cash Flow"
];

export const _categories = [
    "Transportation",
    "Payment Methods",
    "Digital Innovation",
    "Technology",
    "Travel",
    "Logistics",
    "Roadside Assistance",
    "Operational Efficiency",
    "User Experience",
    "Convenience",
    "Data Security",
    "Business Integrity",
    "Compliance",
    "Privacy",
    "Innovation",
    "Home Services"
];

export const JOB_STATUSES = {
    new: 'New',
    accepted: 'Accepted',
    inRoute: 'In Route',
    onScene: 'On Scene',
    towInProgress: 'Tow In Progress',
    destinationArrival: 'Destination Arrival',
    complete: 'Complete',
    cancelled: 'Cancelled',
    goa: 'GOA'
}


export const JOB_QUERY_DATE_OPTIONS = {
    'createdDate': 'Created Date',
    'timeBasedActivity.spottedTimestamp': 'Job Start',
    'timeBasedActivity.clearedTimestamp': 'Job Completion',
    'source.payDate': 'Pay Date',
    // "entitlementProcessStartTime": "Process Start",
    // "scheduledTimestamp": "Scheduled",
    // "dispatchedTimestamp": "Dispatched",
    // "enRouteTimestamp": "En Route",
    // "onLocationTimestamp": "On Location"
}


export const JOB_COMPLETE = (status) => [JOB_STATUSES.goa, JOB_STATUSES.cancelled, JOB_STATUSES.complete].includes(status)

export const JOB_STATES = {
    ProviderOnTheWay: 'Provider on the way',
    providerOnSite: 'Provider on site',
    providerAtDropOff: 'Provider at Drop off',
    providerEnRouteToDropOff: 'Provider En-route to Drop off',
    providerCompleted: 'Provider Completed',
    providerAccepted: 'Provider Accepted',
    dispatchCreated: 'Dispatch created',
    driverAssigned: 'Driver Assigned',
    providerCancelled: 'Provider Cancelled',
    customerCancelled: 'Customer Cancelled',
    customerOrVehicleNotAtPickUp: 'Customer or vehicle not at Pick Up'
}
export const DISPATCH_PHASES = {
    New: {
        status: "New",
        state: "Dispatch created",
        description: "Represents a new job that has been created but not yet assigned to a provider.",
        icon: "ic:baseline-add-task", // Add a new task icon
        color: "primary",
        nextStatus: ['Accepted', 'Assigned', 'CustomerCancelled', 'ProviderCancelled']
    },
    Accepted: {
        status: "Accepted",
        state: "Provider Accepted",
        description: "Indicates that the job has been accepted by a provider.",
        icon: "mdi:handshake-outline", // Handshake icon to show acceptance
        color: "info",
        nextStatus: ['Assigned', 'CustomerCancelled', 'ProviderCancelled']
    },
    Assigned: {
        status: "Assigned",
        state: "Driver Assigned",
        description: "Indicates that the job has been accepted by a provider.",
        icon: "mdi:account-tie", // Icon of a person to signify the assigned driver
        color: "info",
        nextStatus: ['InRoute', 'CustomerCancelled', 'ProviderCancelled']
    },
    InRoute: {
        status: "In Route",
        state: "Provider on the way",
        description: "Provider is on the way to the job location.",
        icon: "mdi:car-arrow-right", // Car icon to indicate being on the way
        color: "info",
        nextStatus: ['OnScene', 'CustomerCancelled', 'ProviderCancelled']
    },
    OnScene: {
        status: "On Scene",
        state: "Provider on site",
        description: "Provider has arrived at the job location.",
        icon: "mdi:map-marker-check", // Check mark on location marker to indicate arrival
        color: "info",
        nextStatus: ['TowInProgress', 'GOA', 'NSR', 'CustomerCancelled', 'ProviderCancelled']
    },
    TowInProgress: {
        status: "Tow In Progress",
        state: "Provider En-route to Drop off",
        description: "A tow is in progress, and the provider's tasks are being completed.",
        icon: "game-icons:tow-truck", // Tow truck icon to represent towing in progress
        color: "info",
        nextStatus: ['DestinationArrival']
    },
    DestinationArrival: {
        status: "Destination Arrival",
        state: "Provider at Drop off",
        description: "Marks the arrival at the job's destination.",
        icon: "mdi:flag-checkered", // Checkered flag to signify arrival at the destination
        color: "info",
        nextStatus: ['ReadyForPayment']
    },
    ReadyForPayment: {
        status: "Complete",
        state: "Ready for Payment",
        description: "Represents the completion of the job that is ready for payment.",
        icon: "fluent:payment-24-regular", // Credit card icon for payment
        color: "secondary",
        nextStatus: ['PaymentReceived']
    },
    PaymentPaused: {
        status: "Complete",
        state: "Payment Paused",
        description: "Represents the completion of the job and payment is paused.",
        icon: "mdi:pause-circle-outline", // Pause icon to indicate payment is paused
        color: "warning",
        nextStatus: ['ReadyForPayment']
    },
    PaymentReceived: {
        status: "Complete",
        state: "Payment Received",
        description: "Represents the completion of the job and payment was received.",
        icon: "mdi:cash-check", // Cash check icon for payment received
        color: "success",
        nextStatus: ['Complete']
    },
    Complete: {
        status: "Complete",
        state: "Provider Completed",
        description: "Represents the completion of the job.",
        icon: "mdi:check-circle-outline", // Checkmark icon to indicate completion
        color: "success",
        nextStatus: []
    },
    GOA: {
        status: "GOA",
        state: "Customer or vehicle not at Pick Up",
        description: "Stands for 'Gone on Arrival', suggesting that either the customer or the vehicle was not present at the pickup location.",
        icon: "mdi:car-off", // Car off icon to indicate gone on arrival
        color: "error",
        nextStatus: []
    },
    NSR: {
        status: "NSR",
        state: "Customer denied the service upon arrival",
        description: "Stands for 'No Service Rendered', suggesting that the customer denied the service once provider arrived.",
        icon: "mdi:cancel", // Cancel icon to represent denial of service
        color: "error",
        nextStatus: []
    },
    CustomerCancelled: {
        status: "Cancelled",
        state: "Customer Cancelled",
        description: "Indicates that the job has been canceled by the customer.",
        icon: "mdi:close-circle-outline", // Close circle icon to indicate cancellation
        color: "error",
        nextStatus: []
    },
    ProviderCancelled: {
        status: "Cancelled",
        state: "Provider Cancelled",
        description: "Indicates that the job has been canceled by the provider.",
        icon: "mdi:close-circle-outline", // Close circle icon to indicate cancellation
        color: "error",
        nextStatus: []
    }
};

export const getNextJobPhase = (state, status) => {
    const currentState = find(values(DISPATCH_PHASES), {state, status});
    const nextStatus = currentState?.nextStatus || [];

    if (nextStatus.length > 0) {
        return keys(DISPATCH_PHASES).filter(key => nextStatus.includes(key)).map(key => DISPATCH_PHASES[key])
    } else return null
}
