// routes
import {
    PATH_BUSINESS_CENTER,
    PATH_DASHBOARD,
    PATH_OPERATIONS_CENTER,
    PATH_RESOURCE_CENTER
} from '../../../routes/paths';
// components
import ICONS from "./NavIcons";


const navConfig = (alerts, businessType) => {
    return [
        // Dashboard
        // ----------------------------------------------------------------------
        {
            subheader: 'dashboard',
            flag: 'pkg_feature_dashboard',
            items: [
                {
                    title: 'operational',
                    flag: 'role_access_dashboard_operational_r',
                    path: PATH_DASHBOARD.operational.root,
                    icon: ICONS.operations,
                },
                {
                    title: 'financial',
                    flag: 'role_access_dashboard_financial_r',
                    path: PATH_DASHBOARD.financial.root,
                    icon: ICONS.ecommerce,
                },
                {
                    title: 'business',
                    flag: 'role_access_dashboard_business_r',
                    path: PATH_DASHBOARD.business.root,
                    icon: ICONS.companies,
                },
            ],
        },

        // Operations Center
        // ----------------------------------------------------------------------
        {
            subheader: 'operations center',
            flag: 'pkg_feature_basic_platform',
            items: [
                {
                    title: 'service',
                    flag: 'role_access_operations_center_service_r',
                    path: PATH_OPERATIONS_CENTER.serviceSink.root,
                    icon: ICONS.serviceSink(),
                },
                {
                    title: 'filing',
                    flag: 'role_access_operations_center_filing_r',
                    path: PATH_OPERATIONS_CENTER.filing.root,
                    icon: ICONS.filing(),
                },
                {
                    title: 'communication',
                    flag: 'role_access_operations_center_communication_r',
                    path: PATH_OPERATIONS_CENTER.communication.root,
                    icon: ICONS.communication(),
                },
                {
                    title: 'finance',
                    flag: 'role_access_operations_center_finance_r',
                    path: PATH_OPERATIONS_CENTER.finance.root,
                    icon: ICONS.finance(),
                },
            ],
        },

        // Business Center
        // ----------------------------------------------------------------------
        {
            subheader: 'business center',
            flag: 'pkg_feature_basic_platform',
            flags: ["role_access_owner", "role_access_dolooma_admin", "role_access_administrator", "role_access_dispatch_supervisor"],
            or: true,
            items: [
                {
                    title: 'operations',
                    flag: 'role_access_business_center_operations_r',
                    path: PATH_BUSINESS_CENTER.operations.root,
                    icon: ICONS.operations
                },
                {
                    title: 'human resources',
                    flag: 'role_access_business_center_human_resources_r',
                    path: PATH_BUSINESS_CENTER.hr.root,
                    icon: ICONS.hr
                },
                {
                    title: 'pricing & offerings',
                    flag: 'role_access_business_center_pricing_offerings_r',
                    path: PATH_BUSINESS_CENTER.pricing.root,
                    icon: ICONS.pricingAndOfferings
                },
                {
                    title: 'resources',
                    flag: 'role_access_business_center_resources_r',
                    path: PATH_BUSINESS_CENTER.resources.root,
                    icon: ICONS.resources
                },
                {
                    title: 'CRM',
                    flag: 'role_access_business_center_crm_r',
                    path: PATH_BUSINESS_CENTER.crm.root,
                    icon: ICONS.crm
                }

            ],
        },

        // Resource Center
        // ----------------------------------------------------------------------
        {
            subheader: 'Resource Center',
            flags: ['pkg_feature_basic_platform', "role_access_dolooma_admin"],
            items: [
                {
                    title: 'system',
                    flag: 'role_access_resource_center_system_r',
                    path: PATH_RESOURCE_CENTER.system.root,
                    icon: ICONS.system,
                },
                {
                    title: 'support',
                    flag: 'role_access_resource_center_support_r',
                    path: PATH_RESOURCE_CENTER.support.root,
                    icon: ICONS.support,
                },
            ]
        },
    ];
}

export default navConfig;
